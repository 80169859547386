import React,{useState,useEffect} from "react";
import heroRight from "../assets/hero_.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import "../utills/publication.css"
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../utills/axios";


const Publications = ()=>{
    const [show,setShow] = useState(false)
    const navigate = useNavigate();
    const itemsPerPage = 12;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const fetchRecipes = async (page) => {
      try {
        let response = await axiosInstance.get(`/procurement?page=${page}&limit=${itemsPerPage}`);
        setTotalPages(response.data?.totalPages);
        setCurrentPage(response.data?.currentPage);
        setItems(response.data?.procurements);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching Procurement:", error);
        toast.error(error.message)
      }
    };
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      fetchRecipes(pageNumber);
    };
    useEffect(() => {
      fetchRecipes(currentPage);
    }, [currentPage]);
   
    return(
       <div style={{marginTop:150,display:"flex",justifyContent:"center",padding:"0 60px",width:"100%",paddingBottom:"40px"}}>
        <section>
            <h2 className="text-is--secondary" style={{textAlign:"center"}}> Easy Procurement</h2>
        <p className="text-is--dark" style={{textAlign:"center"}}>           
        We are your trusted source for top-notch production, Produce with us in CHINA<br/>   
        </p>
        <div className="row align-items-center col-xl-12 col-md-7"  data-aos="zoom-in-up">
        <div className="recipe-container" id='explore'>
        {loading ? <p style={{textAlign:'center',width:'100%'}}>loading...</p> : items.map((item, index) => (
          <div
            key={index}
            className="recipe"
            style={{
              backgroundImage: `url(${item.image})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderRadius: "8px",
              backgroundSize: "cover",
            }}
          >
            <p>{item.title}</p>
            {/* <button onClick={()=>navigate(`/recipe/${item._id}`)}>View {">>"}</button> */}
          </div>
        ))}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>

            
            </div>

        </section>
       </div>
    )
}

export default Publications


export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="paginationWrapper">
      <button
        onClick={() => onPageChange(Number(currentPage) - 1)}
        disabled={Number(currentPage) === 1}
      >
        Prev
      </button>
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={Number(currentPage) === number ? "active" : ""}
        >
          {number}
        </button>
      ))}
      <button
        onClick={() => onPageChange(Number(currentPage) + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};