import './App.css';
import Footer from './components/footer';
import Navbar from './components/nav';
import LandingPage from './pages/landingPage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { BrowserRouter , Routes, Route, json } from 'react-router-dom';
import Signup from './pages/signup';
import Login from './pages/login';
import Sidebar from './components/sideBar';
import HomeDash from './pages/dashboard';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Layout from './pages/dashboard/layout';
import { useGlobalContext } from './utills/store';
import About from './pages/aboutus';
import TermsAndConditions from './pages/terms';
import PrivacyPolicy from './pages/privacy';
import ContactUs from './pages/contactus';
import Ticket from './pages/dashboard/complain';
import Services from './pages/services';
import BusinessNameRegistrationForm from './components/bussiness';
import CompanyRegistrationForm from './components/companyReg';
import IncorporatedTrusteeRegistrationForm from './components/incoporatedTrust';
import PostIncorporationServicesForm from './components/postIncorporation';
import TrademarkRegistrationForm from './components/tradeMark';
import COFODocumentRegistrationForm from './components/cofo';
import ScumlRegistrationForm from './components/scuml';
import MoneyLenderLicenseForm from './components/moneyLender';
import NAFDACForm from './components/nafdac';

import 'react-toastify/dist/ReactToastify.css';
import Agent from './components/contactAgent';
import ForgotPassword from './pages/forgetPassword';
import ResetPassword from './pages/resetPassword';
import Publications from './pages/publications';
import Procurement from './pages/dashboard/procurement';

function App() {
  const navigate = useNavigate();
  const[ isLoggedin,setIsLoggedin] = useState(false)
  const { isLoggedIn,setIsLoggedIn,logOut,setUser } = useGlobalContext();
  const [showMobileNav,setShowMobileNav] =  useState(false)

 
  useEffect(() => {
    AOS.init();
  }, [])


  useEffect(()=>{
    let _isLoggedIn = localStorage.getItem("isLoggedIn");
   let user = JSON.parse(localStorage.getItem("userxx"));
   let active = localStorage.getItem("activeLink");
 
   if(user === null){
    logOut()
   }
   if(user){
    setUser(user);
    if(active !==null){
      // window.location.href = active
     }
   }
    if(_isLoggedIn !== null){
      setIsLoggedin(true)
      setIsLoggedIn(true)
      navigate("/dashboard")
    }
  },[])

  return (
    <>
    {isLoggedIn ? "" : <Navbar showMobileNav={showMobileNav} setShowMobileNav={setShowMobileNav}/>}
 
    <Routes>
      <Route path="/" element={<LandingPage  setShowMobileNav={setShowMobileNav}/>}/>
      <Route path="/signup" element={<Signup />}/>
      <Route path="/login" element={<Login />}/>
      <Route path="/resetpassword" element={<ResetPassword />}/>
      <Route path="/forgot-password" element={<ForgotPassword/>}/>
      <Route path="/about-us" element={<About />}/>
      <Route path="/terms-of-condition" element={<TermsAndConditions />}/>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
      <Route path="/contact-us" element={<ContactUs />}/>
      <Route path="/services" element={<Services />}/>
      <Route path="/easy-procurement" element={<Publications/>}/>

     <Route path="/dashboard"  element={
      <Layout authed={isLoggedIn}>
        <HomeDash />
      </Layout>
     }/> 
     <Route path="/procurement"  element={
      <Layout authed={isLoggedIn}>
        <Procurement />
      </Layout>
     }/>
      <Route path="/newspaper-publication"  element={
      <Layout authed={isLoggedIn}>
        <Agent Message={"Requirement for Newspaper publication"}/>
      </Layout>
     }/> 
     <Route path="/business_name" element={
      <Layout authed={isLoggedIn}>
        <BusinessNameRegistrationForm />
      </Layout>
     }/> 
      <Route path="/company_registration" element={
      <Layout authed={isLoggedIn}>
        <CompanyRegistrationForm />
      </Layout>
     }/>
      <Route path="/incorporated_trustee" element={
      <Layout authed={isLoggedIn}>
        <IncorporatedTrusteeRegistrationForm />
      </Layout>
     }/>
       <Route path="/post_incorporation_services" element={
      <Layout authed={isLoggedIn}>
        <PostIncorporationServicesForm />
      </Layout>
     }/>
      <Route path="/trade_mark" element={
      <Layout authed={isLoggedIn}>
        <TrademarkRegistrationForm />
      </Layout>
     }/> 
       <Route path="/cofo" element={
      <Layout authed={isLoggedIn}>
        <COFODocumentRegistrationForm />
      </Layout>
     }/>  
     <Route path="/scuml" element={
      <Layout authed={isLoggedIn}>
        <ScumlRegistrationForm />
      </Layout>
     }/>
      <Route path="/money_lenders" element={
      <Layout authed={isLoggedIn}>
        <MoneyLenderLicenseForm />
      </Layout>
     }/>
      <Route path="/nafdac" element={
      <Layout authed={isLoggedIn}>
        <NAFDACForm />
      </Layout>
     }/>
     <Route path="/complain" element={
      <Layout authed={isLoggedIn}>
        <Ticket />
      </Layout>
     }/>


    </Routes>
    <ToastContainer />
      {/* </div> */}
  {/* </BrowserRouter> */}
    
    {isLoggedIn ? "" : <Footer/>}
    
    </>
  );
}

export default App;
