// import React from 'react';
import './table.css';
import DataTable from 'react-data-table-component';
import noTrans from "../../assets/No_Transactions.png"



const conditionalRowStyles = [
    {
      when: (row,index) => index % 2 === 0,
      style: {
        backgroundColor: 'rgba(63, 195, 128, 0.9)',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    }]
    const customStyles = {
        headCells: {
          style: {
            fontSize:"16px",
            borderRight: '1px solid #ccc', // Add right border to cells
            whiteSpace: 'normal', // Prevent text wrapping
            overflow: 'visible', // Show overflow content
            textOverflow: 'clip'                                                 
          },
        },
        cells: {
            style: {
                borderRight: '1px solid #ccc', // Add right border to cells
                whiteSpace: 'normal', // Prevent text wrapping
                overflow: 'visible', // Show overflow content
                // textOverflow: 'clip',
            },
          },        
      };
function MyComponent({data,columns}) {
  console.log(data,columns)
    return (
        <DataTable
        // title="Registration History"
            columns={columns}
            data={data}
            noDataComponent={<Nodata/>}
            conditionalRowStyles={conditionalRowStyles}
            customStyles={customStyles} // Apply custom styles here

        />
    );
};


export default MyComponent;


const Nodata=()=>{
    return(
        <div style={{display:"flex",flexDirection:"column",width:"100%",justifyContent:"center",alignItems:"center",gap:60,marginTop:100}}>
            <div style={{width:"40%",alignSelf:"center"}}>
        <img src={noTrans} style={{width:"100%",height:"100%"}}/>
            </div>
        <p style={{textAlign:"center"}}>No Completed Registration Yet</p>
        </div>
    )
}
