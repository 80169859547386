import React, { useState } from "react";
import "./sideBar.css";
import { RiMenu4Fill } from "react-icons/ri";
import { GiNewspaper } from "react-icons/gi";
import { motion } from "framer-motion";
import { AiOutlineClose,AiOutlineLogout } from "react-icons/ai";
import { RiReservedLine } from "react-icons/ri";
import { BiSolidBusiness } from "react-icons/bi";
import { MdAppRegistration,MdOutlineDashboard } from "react-icons/md";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { FaServicestack } from "react-icons/fa";
import { PiTrademark } from "react-icons/pi";
import { GiTargeting } from "react-icons/gi";
import { IoFastFoodOutline,IoCreateOutline } from "react-icons/io5";
import { TbLicense } from "react-icons/tb";
import { TiTicket } from "react-icons/ti";


import { IoMdCreate } from "react-icons/io";
import { useGlobalContext } from "../../utills/store";
// import Logo from "../../assets/logo_.PNG"
import Logo from "../../assets/logo_white.png"
import { NavLink, useNavigate } from "react-router-dom";



const Sidebar = ({showSideBar,setShowSidebar}) => {
  const navigate = useNavigate()

    const SidebarData = [
        {
          icon: MdOutlineDashboard ,
          heading: "Dashboard",
          route:"/dashboard"
        },
        // {
        //   icon: RiReservedLine,
        //   heading: "Name reservation",
        // },
        {
          icon: BiSolidBusiness,
          heading: "Business Name",
          route:"/business_name"
        },
        {
          icon: MdAppRegistration ,
          heading: 'Company Registration',
          route:"/company_registration"
        },
        {
          icon: VscWorkspaceTrusted,
          heading: 'Incorporated Trustee',
          route:"/incorporated_trustee"
        }, 
         {
          icon: FaServicestack,
          heading: 'Post Incorporation Services',
          route:"/post_incorporation_services"
        }, 
         {
          icon: PiTrademark,
          heading: 'Trade Mark',
          route:"/trade_mark"
        }, 
         {
          icon: IoMdCreate,
          heading: 'C Of O Registration',
          route:"/cofo"
        }, 
         {
          icon: IoCreateOutline,
          heading: 'Scuml Registration',
          route:"/scuml"
        },
         {
          icon: TbLicense,
          heading: 'Money Lender’s License',
          route:"/money_lenders"
        }, 
        {
          icon: IoFastFoodOutline,
          heading: 'NAFDAC Registration',
          route:"/nafdac"
        }, 
          {
          icon: GiNewspaper,
          heading: 'Newspaper Publication',
          route:"/newspaper-publication"
        },
        { icon: GiTargeting,
          heading: 'Procurement',
          route:"/procurement"
        },
      ];
      
  const [selected, setSelected] = useState(0);

  const [expanded, setExpaned] = useState(showSideBar)
  const {activeLink, setActiveLink,activeRegistration, setActiveRegistration ,logOut} = useGlobalContext();


  const sidebarVariants = {
    true: {
      left : '0'
    },
    false:{
      left : '-60%'
    }
  }
  return (
    <>
      {/* <div className="bars" style={expanded?{left: '60%'}:{left: '5%'}} onClick={()=>setExpaned(!expanded)}> */}
      <div className="bars" style={showSideBar?{left: '60%'}:{left: '5%'}} onClick={()=>setShowSidebar(!showSideBar)}>
      {showSideBar ? <AiOutlineClose/> : <RiMenu4Fill />}
      </div>
    <motion.div className='sidebar'
    variants={sidebarVariants}
    animate={window.innerWidth<=768?`${showSideBar}`:''}
    // animate={window.innerWidth<=768?`${expanded}`:''}
    >
      {/* logo */}
      <div className="logo">
        <img src={Logo} alt="logo"  style={{width:"35%",height:"auto"}}/>
        {/* <p>logo</p> */}
      </div>

      <div className="menu">
        {SidebarData.map((item, index) => {
          return (
            <NavLink to={item.route} 
            className={({ isActive, isPending }) =>
              isPending ? "menuItem" : isActive ? "menuItem active" : "menuItem"
            }
              
              key={index}
              onClick={() => {
                setSelected(index)
                setActiveLink(item.route)
                setShowSidebar(!showSideBar)
                // setActiveRegistration(item.heading)
              }}
            >
              <item.icon />
              <span>{item.heading}</span>
            </NavLink>
          );
        })}
        {/* signoutIcon */}
        <div  className= "menuItem" style={{border:`1px solid ${activeRegistration === "complain" ?'#66B875' :'white'}`,marginRight:"30px",paddingLeft:"10px"}} onClick={()=>{
         setShowSidebar(!showSideBar)
          setActiveRegistration('complain')
          navigate("/complain")
        }}>
         <TiTicket />
         <span>{"Create Ticket"}</span>
        </div> 
        <div className="menuItem" onClick={()=>{
          logOut();
          navigate("/login")
        }}>
         <AiOutlineLogout/>
         <span>{"SignOut"}</span>
        </div>
      </div>
    </motion.div>
    </>
  );
};

export default Sidebar;