import React, { useState, useEffect } from "react";
import Agent from "../../components/contactAgent";
import { useGlobalContext } from "../../utills/store";
import ResponsiveTable from "../../components/tabe";
import { MdOutlineDeleteForever } from "react-icons/md";
import axiosInstance from "../../utills/axios";
import { IoMdCloudUpload } from "react-icons/io";
import { Salutation } from "./admin";
import { Typography,TextField, Modal,Box,Button,Input } from "@mui/material";
import { Pagination } from "../publications";
import { toast } from "react-toastify";

const Procurement = () => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [Errormessage, setErrorMessage] = useState('');
  const [loading, SetIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting  ] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const itemsPerPage = 12;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();

  const { user } = useGlobalContext();

  const columns = [
    {
      name: "N/O",
      selector: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Image",
      selector: (row) => (
        <div style={{ padding: "20px 0" }}>
          <img src={row.image} style={{ width: 200, height: 200 }} />
        </div>
      ),
    },

    {
      name: "Action",
      selector: (row) => (
        <button
          onClick={() => {
            DeleteProcurement(row._id)
            // setPreviewContent(row.serviceDetails);
            // setIsPreview(true);
          }}
          style={{
            border: "none",
            background: "red",
            color: "white",
            borderRadius: "50px",
            padding: "5px",
          }}
          title="delete procurement"
        >
          <MdOutlineDeleteForever size={20} />
        </button>
      ),
      // selector: row => formatDate(row.serviceDetails),
    },
  ];
  const handleFilePick=(state)=> async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setImage(base64String);
        };
        reader.readAsDataURL(file);
      }
    } catch (err) {
      setErrorMessage("Error picking the file");
    }
  };

  const GetRequest = async (page) => {
    try {
      
   

    let response = await axiosInstance.get(`/procurement?page=${page}&limit=${itemsPerPage}`);
    
    if (response.status === 200) {
      setTotalPages(response.data?.totalPages);
    setCurrentPage(response.data?.currentPage);
      setData(response.data.procurements);
      SetIsLoading(false)
    }
  } catch (error) {
      toast.error(error.message)
  }
  };
   const DeleteProcurement = async (id) => {
    try {

    let response = await axiosInstance.delete(`/procurement/${id}`);
    
    if (response.status === 200) {
      let reserved = data.filter((item)=>item._id !== id);
      setData(reserved);
    toast.success(response.data)
      SetIsLoading(false)
    }
  } catch (error) {
    
      toast.error(error.message)
  }
  };

  const handleSubmit = async()=>{
    try {
      setIsSubmitting(true)
   
    const token = localStorage.getItem('tok_xx')
    const response =  await axiosInstance.post(`/admin/procurement`,{title,image},{
      headers:{
        "Authorization":`Bearer ${token}`
      }
    })
    toast.success(response.data);
    GetRequest(1).then();
    setIsSubmitting(false)
    setOpenModal(false);
  } catch (error) {
      toast.error(error.message)
  }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    GetRequest(pageNumber).then();

  };

  useEffect(() => {
    GetRequest(1).then();
  }, []);

  return (
    <>
      {user.role === "admin" ? (
        <>
         <Modal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box className="contact_box">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            style={{ textAlign: "center", margin: "30px 0" }}
          >
            Upload Procurement Image
          </Typography>
          <div style={{ width: "100%" }}>
            <TextField
              label="Title"
              type="text"
              name="title"
              variant="outlined"
              value={title}
              onChange={(e)=>setTitle(e.target.value)}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
            <label>Image*</label>
            <Input
              fullWidth
              type="file"
              onChange={handleFilePick("idCardCopy")}
            />
            
             {Errormessage ? (
          <Typography variant="body2" color="error">
            {Errormessage}
          </Typography>
        ) : null}

            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
              style={{ marginTop: "16px", background: "var(--nyd-secondary)" }}
            >
              {isSubmitting ? 'Please wait...' : 'Proceed'}
            </Button>
           
          </div>
        </Box>
      </Modal>
          <div className="MainDash" style={{ padding: "0 30px" }}>
            <p
              style={{
                color: "#08384e",
                fontSize: 20,
                marginTop: "4rem",
                width: "100%",
                textAlign: "left",
              }}
            >
              {Salutation()},
              <span style={{ color: "black" }}>
                {" "}
                {user?.fullName?.split(" ")[0]} !
              </span>{" "}
            </p>
            <p>
              <b>{"Procurements Lists"}</b>
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "30px",
                marginBottom:'20px'
              }}
            >
              <button
                onClick={() => {
                  // setPreviewContent(row.serviceDetails);
                  // setIsPreview(true);
                  setOpenModal(true)
                }}
                style={{
                  border: "none",
                  background: "#A060CC",
                  color: "white",
                  borderRadius: "50px",
                  padding: "5px",
                }}
                title="delete procurement"
              >
                <IoMdCloudUpload size={20} /> Upload
              </button>
            </div>

            
          {  data.length ? (
            <div style={{marginBottom:'40px'}}>
            <ResponsiveTable data={data} columns={columns} />
           
            </div>
          ) : (
        loading ?  <p>Loading...</p> : <p>No Procurement created.</p>
        )}
         <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
          </div>
       </>
      ) : (
        <>
          <Agent Message={"Requirement for Procurement"} />
        </>
      )}
    </>
  );
};

export default Procurement;
